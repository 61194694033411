@import "src/assets/mixins";
@import "src/assets/variables";

.container{
  padding: 0px 5px 5px 5px;
  background-color: $color-7;
    
  @media screen and (max-width: 1150px){   
    padding: 0px;
    // background-color: $white;
  }
  @media screen and (max-width: $mainMobileBreakPoint){   
    button{
      height: 40px;      
    }
  }
  @media screen and (max-width: 760px){   
    background-color: $white;
  }
  @media screen and (max-width: 480px){   
    top: -10px;
  }
  
}
.buttonPanel {
  max-width: 1250px;
  top:0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  gap:20px;
  @media screen and (max-width: $mainMobileBreakPoint) {  
    margin-bottom: 15px;
  }
  @media screen and (max-width: 810px){ 
    gap: 0px;
  }
  @media screen and (max-width: 540px){ 
    margin-bottom: 10px;
  }
  @media screen and (max-width: 480px){ 
    display: block;
    
  }
  
}
.leftWrapper {
  gap: 10px;
  justify-content: space-between;  
  display: flex;
  align-items: center;
  @media screen and (max-width: 810px){ 
    width: 100%;
  }
  @media screen and (max-width: 540px){ 
    flex-direction: column;    
  }
}
.calendarWrap{
  display: flex;
  .calendarButton {
    user-select: none;
    @media screen and (max-width: $mainMobileBreakPoint){ 
      display: none;
    }
  } 
  @media screen and (max-width: 540px){
    display: none;
  } 
}
.calendarButton {
  width: 84px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  @media screen and (max-width:580px){
    gap: 1px;
    width: 72px;
  }
.prev, .next {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  display: flex;
  justify-content: center;  
  align-items: center;
  svg{
    cursor: pointer;
  }
  &:hover{
    background-color: $color-17;
    &  .help {
      display: block;      
      
      z-index: 5;      
    }
  }
  }
  position: relative;
}
.next{
  transform: rotate(180deg);
}
.prev:hover .help{
  bottom:65px;
  left: -15px;
  @media screen and (max-width: $mainMobileBreakPoint){   
    bottom: 40px;
  }
  @media screen and (max-width: 540px){   
    left: 5px;
  }

} 

.next:hover .help{
  transform: rotate(180deg);
  top:46px;
  left: -15px;
  @media screen and (max-width: $mainMobileBreakPoint){   
    top:36px;
  }  
}
.help {
  display: none;
  position: absolute;
  background-color: $color-4;
  border-radius: 6px;
  box-shadow: $shadow-2;
  text-wrap: nowrap;
  padding: 4px 8px;
  cursor: none;
  user-select: none;
  @include h5;
  font-weight: 400;
  color: $white;
  text-decoration: none;
  @media screen and (max-width: 640px){   
    font-size: 12px;
    padding: 2px 7px;
  }
}
.calendar{
  input{
    width: 356px;
    @media screen and (max-width: 1240px){
      width: 282px;
    }
    @media screen and (max-width: 620px){
      width: 235px;
    }  
  }
}

.todayMobile{
 display: none;
 @media screen and (max-width: $mainMobileBreakPoint){ 
     display: flex;
     justify-content: center;
     outline: none;
     user-select: none;
     
 }
 @media screen and (max-width: 540px){ 
   align-items: center;
   width: 100%;   
 }
 @media screen and (max-width: 480px){ 
   margin-bottom: 10px; 
 }
 .todayWrap{
   display: flex;
   width: 313px;
   justify-content: space-between;
     
   
   @media screen and (max-width: 660px){
     width: auto;
   }
   @media screen and (max-width: 540px){
     width: 300px;
   }
 }
}
.todayDesk{
  @media screen and (max-width: $mainMobileBreakPoint){ 
    display: none;
  }
}
.addPriceBtn {
  @media (max-width: 480px) {
    width: 100%;
  }
}
.tableWrap{
  max-width: 1250px;
  width: inherit;
}