@import "src/assets/variables";
.container {
  display: flex;
  width: 100vw;
  @media screen and (max-width: $mainMobileBreakPoint) {
    display: block;
  }
}

.rightWrapper {
  flex: 1;

  @media (max-width: $mainMobileBreakPoint) {
    padding-top: 44px;
  }
}

.contentWrapper {
  padding: 10px 20px;
  overflow-y: auto;
  height: calc(100vh - 110px);
  display: flex;
  flex-direction: column;
  width: calc(100vw - 102px);
  @media (max-width: $mainMobileBreakPoint) {
    overflow-y: unset;
    width: 100vw;
    // height: calc(100vh - 115px);
  }
  @media (max-width: 500px) {
    padding: 10px 3px;
  }
  @media (max-width: 440px) {
    overflow-y: unset;
    max-height: calc(100vh - 135px);
  }  
  @media (max-width: 324px) {
    overflow-y: unset;
    max-height: calc(100vh - 185px);
  }  
}
