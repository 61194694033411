@import "src/assets/mixins";

.container {
  position: relative;
  width: 102px;
  height: 100vh;
  min-height: 470px;
  @media screen and (max-width: $mainMobileBreakPoint) {
    display: none;
  }
}

.menu {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: $primary;
  display: flex;
  flex-direction: column;
  padding: 28px 0;
  z-index: 100;
  transition: $transition;
  overflow: hidden;

  &:hover {
    width: 273px;
  }
  @media screen and (max-height: 660px) and (orientation: landscape) {
    justify-content: space-between;
    gap: 30px;
  }
  @media screen and (max-height: 560px) and (orientation: landscape) {
    justify-content: space-between;
    padding-bottom: 10px;
    gap: 14px;
  }
}

.logoWrapper {
  position: relative;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shortLogo,
.logo {
  position: absolute;
  opacity: 0;
  transition: $transition;
}

.shortLogo {
  left: 30px;
}

.logo {
  left: 31px;
}

.navigation {
  padding: 0 30px 0px 30px;
  display: flex;
  align-items: center;
  flex: 1;
}

.navlist {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  gap: 30px;
  @media screen and (max-height: 660px) and (orientation: landscape) {
    justify-content: space-between;
    gap: 5px;
  }
}

.link {
  position: relative;
  &:hover .titleLink {
    color: $white;
  }

  @media screen and (max-width: $mainMobileBreakPoint) {
    height: auto;
    display: flex;
    align-items: center;
    gap: 5px;
  }
}

.titleLink {
  @include h4($color-6);
  opacity: 0;
  transition: $transition;
  position: absolute;
  width: 161px;
  left: 50px;
  top: 50%;
  transform: translateY(-50%);
  @media screen and (max-width: $mainMobileBreakPoint) {
    width: auto;
    position: static;
    top: 0px;
    transform: none;
    left: 0px;
  }
}

.btnWrapper,
.btnWrapperStroke {
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg > path,
  svg > rect {
    transition: $transition;
  }
}

.link:hover > .btnWrapper svg > path {
  fill: $white;
}
.link:hover > .btnWrapper svg > rect {
  stroke: $white;
}

.link:hover > .btnWrapperStroke svg > path {
  stroke: $white;
}
.link:hover > .btnWrapperStroke svg > rect {
  stroke: $white;
}

.activeLink {
  .avtiveLinkText {
    color: $white;
  }

  .activeLinkIcon svg > path {
    fill: $white;
  }
  .activeLinkIcon svg > rect {
    stroke: $white;
  }

  .activeLinkIconStroke svg > path {
    stroke: $white;
  }
  .activeLinkIconStroke svg > rect {
    stroke: $white;
  }
}

.show {
  opacity: 1;
}
.mobileWrapper {
  background-color: $primary;
  position: fixed;
  z-index: 21;
  width: 100%;
  display: none;

  @media (max-width: $mainMobileBreakPoint) {
    display: block;
  }

  .mobileIcon {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .checkbox {
    position: absolute;
    display: block;
    height: 40px;
    width: 40px;
    top: 0px;
    right: 8px;
    z-index: 100;
    opacity: 0;
    cursor: pointer;
    margin: 0px;
  }
  .mobileContainer {
    height: 44px;
    position: relative;
    background-color: $primary;
    padding: 0px 8px;
    z-index: 4;
    @media screen and (max-width: $mainMobileBreakPoint) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .burger {
      position: relative;
      width: 40px;
      height: 40px;
    }
    .rightMobile {
      display: flex;
      gap: 10px;
      position: relative;
    }
    .hamburgerLines {
      display: block;
      height: 15px;
      width: 19px;
      position: absolute;
      top: 12px;
      left: 11px;
      // z-index: 2;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      span {
        display: block;
        height: 2px;
        width: 100%;
        border-radius: 2px;
        background: $white;
      }
      .line1 {
        transform-origin: 0% 0%;
        transition: $transition;
      }

      .line2 {
        transition: transform 0.2s ease-in-out;
      }

      .line3 {
        transform-origin: 0% 100%;
        transition: $transition;
      }
    }
  }
  input[type="checkbox"]:checked + .mobileContainer .hamburgerLines .line1 {
    transform: rotate(45deg);
  }

  input[type="checkbox"]:checked + .mobileContainer .hamburgerLines .line2 {
    transform: scaleY(0);
  }

  input[type="checkbox"]:checked + .mobileContainer .hamburgerLines .line3 {
    transform: rotate(-45deg);
  }
  .mobMenu {
    position: absolute;
    right: 0px;
    background-color: $primary;
    width: 100%;
    z-index: 6;
    padding: 20px 8px;
    transform-origin: 0% 100%;
    transition: height 0.5s ease-in-out;
    top: -500px;
    .mobNavigation {
      margin-bottom: 24px;
    }
    &.landscape {
      padding-top: 5px;
      .mobNavigation {
        margin-bottom: 10px;
      }
    }
  }
  input[type="checkbox"]:checked ~ .mobMenu {
    display: block;
    top: 44px;
    transition: top 0.5s ease-in-out;
  }

  .titleLink {
    opacity: 1;
  }
  .navlist {
    gap: 5px;
  }
}
.shadowFilter {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: $color-8;
  opacity: 0.5;
  z-index: 1;
  top: 0px;
  left: 0px;
}
.ownerWrap {
  width: 288px;
  z-index: 21;
  padding: 20px 20px 12px 20px;
  background-color: $white;
  position: fixed;
  left: 16px;
  top: 44px;
  border-radius: $radius;
  box-shadow: $shadow-2;
  .ownerHead {
    display: flex;
    justify-content: space-between;
  }
  .ownerTitle {
    @include h3mob;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ownerStatus {
    @include h4mob;
    margin-bottom: 20px;
  }
  .ownerAction {
    @include h4;
    padding-bottom: 8px;
    display: block;
  }
}
.footer {
  padding: 0 30px;
  display: flex;
  align-items: flex-end;
  gap: 5px;
  position: relative;
  height: 160px;
  @media screen and (max-height: 600px) and (orientation: landscape) {
    height: 136px;
  }
  .infoIcon {
    flex-shrink: 0;
    width: 40px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    // transition: $transition;
    &.show {
      opacity: 1;
    }
  }
  .contacts {
    display: none;
    transition: $transition;
    &.show {
      display: block;
    }
    position: absolute;
    bottom: 0px;
    left: 34px;
  }
}
