@import "src/assets/mixins";

.wrapper {
  position: relative;
  &.bookingList {
    position: static;
  }
}

.primaryBtn {
  @include button();
  background-color: $primary;
  width: 100%;

  &:active {
    background-color: $color-8;
  }
}

.secondaryBtn {
  @include button($text-primary);
  background-color: $white;
  box-shadow: $shadow-2;
  width: 100%;

  &:active {
    background-color: $color-8;
    color: $white;
  }
}

.primarySmallBtn {
  @include buttonSmall();
  background-color: $primary;

  &:active {
    background-color: $color-8;
  }
  &.mobileButton {
    height: 40px;
  }
}

.secondarySmallBtn {
  @include buttonSmall($text-primary);
  background-color: $white;
  box-shadow: $shadow-2;

  &:active {
    color: $white;
    background-color: $color-8;
  }
  
}

.smallBtn,
.errorSmallBtn {
  background-color: $white;
  border-radius: $radius;
  box-shadow: $shadow-2;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  transition: $transition;
  cursor: pointer;

  svg > path {
    transition: $transition;
  }
}

.smallBtn {
  &:active {
    background-color: $color-8;
  }
}

.errorSmallBtn {
  &:active {
    background-color: $error;
  }
}

//

.errorSmallBtn.fillIcon {
  svg > path {
    fill: $error;
  }

  &:hover {
    svg > path {
      fill: $error;
    }
  }

  &:active {
    svg > path {
      fill: $white;
    }
  }
}

.errorSmallBtn.strokeIcon {
  svg > path {
    stroke: $error;
  }

  &:hover {
    svg > path {
      stroke: $error;
    }
  }

  &:active {
    svg > path {
      stroke: $white;
    }
  }
}

//

.errorSmallBtn.activeSmallBtn.fillIcon {
  background-color: $error;

  svg > path {
    fill: $white;
  }

  &:hover {
    svg > path {
      fill: $white;
    }
  }
}

.errorSmallBtn.activeSmallBtn.strokeIcon {
  background-color: $error;

  svg > path {
    stroke: $white;
  }

  &:hover {
    svg > path {
      stroke: $white;
    }
  }
}

//

.fillIcon {
  &:hover {
    svg > path {
      fill: $color-8;
    }
  }

  &:active {
    svg > path {
      fill: $white;
    }
  }
}

.strokeIcon {
  &:hover {
    svg > path {
      stroke: $color-8;
    }
  }

  &:active {
    svg > path {
      stroke: $white;
    }
  }
}

//

.activeSmallBtn {
  background-color: $color-8;
  color: $text-white;
}

.activeSmallBtn.fillIcon {
  svg > path {
    fill: $white;
  }

  &:hover {
    svg > path {
      fill: $white;
    }
  }
}

.activeSmallBtn.strokeIcon {
  svg > path {
    stroke: $white;
  }

  &:hover {
    svg > path {
      stroke: $white;
    }
  }
}

//

.linkBtn {
  cursor: pointer;
  transition: $transition;
  @include h4($text-secondary);

  &:hover {
    color: $primary;
  }

  &:active {
    color: $color-8;
  }
}

.confirmWindow {
  position: absolute;
  width: 185px;
  box-shadow: $shadow-2;
  border-radius: $radius;
  background-color: $white;
  bottom: calc(100% + 20px);
  transform: translateX(-50%);
  left: 50%;
  overflow: hidden;
  z-index: 5;
  &.bookingConfirm{    
    right: 30px;
    bottom: -15px;
    transform: translateX(0%);
    left: auto;
    width: 240px;
    @media screen and (max-width:1440px){
      bottom: 5px;
    }
  }
  .confirmText {
    padding: 6px 10px 2px;
    text-align: center;
  }
  .confirmControls>div{
    height: 25px;
    @media screen and (max-width:1440px){
      height: 38px;
    }
  }
  &.bookingFormConfirm {
    bottom: auto;
    top:calc(100% + 20px);
    @media screen and (max-width:$bookingFormBreakPoint2){
      transform: translateX(0%);
      right: -20px;
      left: auto;
      top:calc(100% + 5px);
    }
  }
}

.confirmText {
  padding: 12px 20px 8px;
  text-align: center;

  @include h4();
}

.confirmControls {
  display: flex;

  @include b3($text-primary);

  div {
    flex: 1;
    height: 41px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: $transition;

    &:hover {
      background-color: $primary;
      color: $text-white;
    }

    &:active {
      background-color: $color-8;
      color: $text-white;
    }
  }
}

.disabledBtn {
  cursor: not-allowed;
  background-color: $color-4;
  color: $text-white;
  // pointer-events: none;

  &:active {
    background-color: $color-4;
  }
  &.deleteBookingList {
    background-color: $white;
  }
}

.disabledBtn.fillIcon {
  svg > path {
    fill: $white;
  }

  &:active {
    svg > path {
      fill: $white;
    }
  }

  &:hover {
    svg > path {
      fill: $white;
    }
  }
  &.deleteBookingList {
    svg > path {
      fill: $color-6;
      opacity: 0.25;
    }
  }
}

.disabledBtn.strokeIcon {
  svg > path {
    stroke: $white;
  }

  &:active {
    svg > path {
      stroke: $white;
    }
  }

  &:hover {
    svg > path {
      stroke: $white;
    }
  }
}

.disabledBtn.linkBtn {
  background-color: transparent;

  &:hover {
    color: $text-secondary;
  }

  &:active {
    color: $text-secondary;
  }
}

.promptText {
  @include h5($text-error);
  // position: absolute;
  // top: calc(100% + 5px);
  // left: 20px;
  text-align: left;
  margin: 5px 0 0 20px;
}

.responseText {
  color: $color-12;
}
.bookingList {
  .primarySmallBtn.smallBookingList {
    box-shadow: $shadow-2;
    width: 40px;
    height: 40px;
    &.fillIcon {
      font-size: 36px;
      font-weight: 200;
    }
  }
}
.secondarySmallBtn.mobileButton {
  height: 40px;
  @include b3($text-primary);
}
.primarySmallBtn.mobileButton {
  @include b3;
}
