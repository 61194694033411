@import "src/assets/mixins";
@import "src/assets/variables";

$cellHeigth:73px;
$mobileHeigth:50px;
$headMobileHeigth:62px;

.tableGrid{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: $white;
  height: $cellHeigth;
  box-sizing: border-box;
  border: $border-table;
  position: relative;
  @media screen and (max-width: $mainMobileBreakPoint){
    height: $mobileHeigth;
  }
  &.head{
    position: sticky;
    top:0px;
    z-index: 5;
    
    @media screen and (max-width: 600px){
      justify-content: space-between;
      height: $headMobileHeigth;
    }
  }
  &.weekendGrid{
    background-color: $color-36;
  }
  &.selectedGrid{
    border-left: 1px solid $color-8;    
    border-right: 1px solid $color-8;   
  }
  &.todayGrid{
    border-left: 1px solid $primary;    
    border-right: 1px solid $primary;   
  }

}
