@import "src/assets/mixins";

.contentTitle {
  @include h3();
  margin-bottom: 16px;

  @media (min-width: 761px) {
    margin-bottom: 20px;
    margin-top: 20px;
  }

  @media (max-width: 760px) {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
.prices {
  max-width: 970px;
  display: grid;
  flex: 1;
  gap: 16px;

  @media (min-width: 761px) {
    gap: 25px 20px;
  }

  @media (min-width: 650px) {
    grid-template-columns: repeat(2, 1fr);
  }
}
.label {
  @include h4($text-secondary);
  margin-bottom: 10px;
}
.month {
  :global(.customSelectV2__control) {
    width: 130px;

    @media (min-width: 761px) {
      width: 200px;
    }
  }
}
.day {
  :global(.customSelectV2__control) {
    @media (min-width: 761px) {
      width: 95px;
    }
  }
}
.dates {
  display: flex;
  
  gap: 16px;

  @media (min-width: 761px) {
    gap: 25px;
  }
  @media (max-width: 1200px) {
    flex-direction: column;
  }
}
.date {
  display: flex;
  gap: 10px;
  max-width: 465px;
  @media (max-width: 360px) {
    flex-direction: column;
  }
}
.errorText {
  @include h5($text-error);
  margin: 5px 0 0 20px;
}