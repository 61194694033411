@import "src/assets/mixins";

.currency {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 10px;

  @media (min-width: 761px) {
    margin-bottom: 20px;
  }
}

.currencyTitle {
  padding: 8px;
  background-color: #f6faff;
  border-radius: $radius;

  @media (min-width: 761px) {
    background-color: $white;
  }
}



.mainBtnsWrapper {
  display: flex;
  gap: 10px;
  margin-bottom: 30px;
  margin-top: 15px;

  @media (min-width: 481px) {
    gap: 20px;
    justify-content: flex-end;
    align-items: flex-end;
    flex: 1;
  }

  @media (max-width: 480px) {
    flex-direction: column;
  }
}

.mainBtn {
  @media (max-width: 480px) {
    width: 100%;
  }
}

