@import "src/assets/mixins";
@import "src/assets/variables";

.container{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-28;
  // opacity: 0.95;
  @include h4;
  font-weight: 500;
  height: 71px;
  position:absolute;
  top: 0px;
  z-index: 3;
  overflow: hidden;
  letter-spacing: 0.3px;
  padding: 2px;
  
  &.regular_high {
    background-color: $color-20;
  }
  &.weekend_high {
    background-color: $color-19;
  }
  &.regular_low {
    background-color: $color-24;
    
  }
  &.weekend_low {
    background-color: $color-32;
    
  }
  &.certain {
    background-color: $color-22;
  }
  @media screen and (max-width: 1048px){
    opacity: 0.95;
    @include h4mob($text-primary);
    font-weight: 500; 
      
  }
  @media screen and (max-width: $mainMobileBreakPoint){
    height: 48px;
  }
  @media screen and (max-width: 600px){
    font-size: 11px;
    // justify-content: flex-start; 
  }
  &:hover ~ .inner{
    opacity: 100%;
  }
}
.inner {
  position: absolute;
  padding: 8px;
  left: 70%;
  bottom: 48px;
  width: 130px;
  height: 68px;
  z-index: 15;
  opacity: 0%;
  overflow: visible;
  box-shadow: $shadow-2;
  @include b3($text-primary);
  &:hover {
    opacity: 100%;
  }
  &.regular_low {
    background-color: $color-27;
  }
  &.regular_high {
    background-color: $color-29;
  }
  &.weekend_low {
    background-color: $color-35;
  }
  &.weekend_high {
    background-color: $color-30;
  }
  &.certain {
    background-color: $color-28;
  }
  @media screen and (max-width: $mainMobileBreakPoint){
    @include reg12mobSemi;
    width: 120px;
    bottom: 32px;
  }
}
.editBtn{
  margin-top: 10px;
  @include h4mob;
  cursor: pointer;
}