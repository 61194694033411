@import "src/assets/mixins";

.contentTitle {
  @include h3();
  margin-bottom: 16px;

  @media (min-width: 761px) {
    margin-bottom: 20px;
  }

  @media (max-width: 760px) {
    font-size: 16px;
  }
}


.certainList {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  gap: 15px;
  margin-top: 20px;
  @media (min-width: 761px) {
    gap: 15px;
  }
}

.addPriceBtn {
  @media (max-width: 480px) {
    width: 100%;
  }
}
.chessBlock{
  margin-bottom: 25px;
}
 @keyframes borderAnimate {
  from {
    border-color:$primary; 
  }  
  50% {
    border-width: 2px; 
  }
  to {
    border-color:transparent;
  }
}
.active{
  border:2px solid transparent;
  border-radius: $radius;
    
}
.animate{
  animation: borderAnimate 3s ease-out;
}  