@import "src/assets/mixins";
@import "src/assets/variables";

.container{
  width: 100%; 
  padding-top: -15px;
    
  @media screen and (max-width: 1140px){
    padding-top: 10px;
  } 

  @media screen and (max-width: $mainMobileBreakPoint){      
    background-color: $color-7;
    padding: 16px;
  } 
  @media screen and (max-width: $bookingListMobileBreakPoint2){      
    padding: 5px;
  }
  position: relative;
  .header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 108px;    
    padding-left: 10px;
    padding-right: 10px;
    gap:30px;
    // position: fixed;
    top:0px;
    @media screen and (max-width: 1200px){      
      padding: 0px;
      gap:15px;
    } 
    @media screen and (max-width: $mainMobileBreakPoint){      
      display: none;
    } 
  }
  .main{
    display: flex;    
    justify-content: stretch;
    width: calc(100vw - 142px);
    
    gap: 36px;
    background-color: $color-7;
    padding: 20px 12px 12px 12px;
    height: 600px;
    position: relative;
    // overflow-y: scroll;
    // scrollbar-color: $primary $color-26;
    // scrollbar-width: thin; 
    @media screen and (max-width: 1440px){
      gap: 15px;
    } 
    @media screen and (max-width: $mainMobileBreakPoint){   
      width: 100%;
      padding: 10px 0px 0px 0px;
      .notviewedScreen {
        display: none;
      }
    } 
    @media screen and (max-width: 500px){   
      padding: 6px 0px 0px 0px;
    } 
    .leftColumn{
      flex: 1 0 300px;
      position: relative;
      height: inherit;
      
      @media screen and (max-width: $mainMobileBreakPoint){      
        display: none;
      } 
      // @media screen and (max-width: 1200px){        
      //   width: 100%;       
      //   margin-bottom: 20px;
      // } 
      .stickyWrap{
        position: sticky;
        top: 0px;
        left: 0px;
        width: inherit;
        
      }
      .dateSelect{
        margin-top: 16px;        
      }
    }    
    .rightColumn{
      width: 100%;
      min-width: 515px;
      overflow-y: scroll;
      scrollbar-color: $primary $color-26;
      scrollbar-width: thin; 
      padding: 5px;
      @media screen and (max-width: $mainMobileBreakPoint){  
        // width: calc(100% - 50px);
        min-width: 250px;
      }
      @media (max-width: $bookingListMobileBreakPoint2) { 
        padding: 5px 4px;
      }
    }

  }
  .title{
    @include b2($text-primary);
    font-weight: 600;
    margin-bottom: 20px;   
  }
  
}
.filterScreen{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  @include b3($color-8);
  font-size: 12px;
  gap:6px;
}

.count{
  height: 25px;
  padding: 5px 12px 5px 12px;
  background-color: $white;
  border-radius: $radius;
}
.list{
  position: relative;
}
.selectHeader{
  @include h4($color-4);
  margin-bottom: 10px;
}
.btnReset{
  margin-top: 36px; 
}
.empty{
  position: relative;
}
.btnNew{
  min-width: 148px;
  flex-shrink: 0;
  @media screen and (max-width: $bookingListMobileBreakPoint2){ 
    display: none;
  }
}
.btnMobile{
  display: none;
  @media screen and (max-width: $bookingListMobileBreakPoint2){ 
    display: block;
    width: 40px;
  }
}
.headerMobile{
  display: none;
  @media screen and (max-width: $mainMobileBreakPoint){      
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    position: relative;
    .leftMobHead{
      display: flex;
      gap: 16px;
    }
    .rightMobHead{ 
      height: 38px;   
      display: flex;     
      svg {
        width: 25px;
        height: 25px;
      }   
      gap: 8px;
    }
  } 
  .statusNote{
    @include h4mob($text-primary);
  }
  .statusWindow{
    position: absolute;
    right: 0px;
    top: 50px;
    z-index: 20;
  }
}
.shadowFilter{
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: $color-8;
  opacity: 0.5;
  z-index: 11;
  top:0px;
  left: 0px;
}
:global .myCalendar{
  width: 300px;
  border: none;
  border-radius: 16px;
  box-shadow:$shadow-1;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  display: inline-block;
  z-index: 6;
  // overflow: hidden;
  padding: 8px;
  background-color: $white;
  @media screen and (max-width: $mainMobileBreakPoint){   
    width: 282px;
    font-size: 14px;
    
  }
}
.notviewedScreen {  
  display: flex;
  height: 25px;
  align-items: center;  
  margin-bottom: 16px;  
  @media screen and (max-width: $mainMobileBreakPoint){   
    margin-bottom: 0px;
  }
}
.notviewedWrap {
  background-color: $color-19;
  border-radius: $radius;
  height: 25px;
  display: flex;
  align-items: center; 
  padding: 0px 12px 0px 12px;
  @include reg12mobSemi;
  color: $text-error;
  font-weight: 600;
}